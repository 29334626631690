<template>
  <div class="show-module">
    <!--标题搜索-->
    <div class="module-head-search">
      <div class="moduletitle">成为Gfast开发者，共同分享插件市场</div>
      <div class="moduledesc">您提供的插件必须是基于Gfast框架开发的应用插件！</div>
      <div class="modulesearch">
        <div class="search-wrapper">
          <input type="text"
                 id="registerVal"
                 ref="searchKey"
                 placeholder="填写你要搜索的插件">
          <div class="module-btn"
               @click="searchPlugin">搜索</div>
        </div>
      </div>
    </div>
    <!--插件详情-->
    <div class="module-detail">
      <div class="module-detail-slide">
        <el-carousel trigger="click"
                     class="img-slid-container" height="460px">
          <el-carousel-item v-for="item in pluginImgs"
                            :key="item.uid"
                            class="img-slid">
            <el-image :src="item.url" style="width: 100%; height: 460px" :preview-src-list="pluginsImgList"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="module-detail-info">
        <h2>{{details.pluginName}} </h2>
        <div class="mdi"><span>功能分类：</span>{{details.cateName}}&nbsp;<span>下载次数：</span>{{details.downloadTimes}}</div>
        <div v-if="details.pluginDiscount!==100" class="mdip"> 基础版：￥<i class="yp">{{details.pluginPriceStr}}</i>&nbsp;&nbsp;&nbsp;折后价：￥<i>{{fenToYuanDiscount(details.pluginPrice,details.pluginDiscount)}}</i></div>
        <div v-if="details.pluginDiscount!==100" class="mdip"> 高级版：￥<i class="yp">{{details.pluginPriceStr2}}</i>&nbsp;&nbsp;&nbsp;折后价：￥<i>{{fenToYuanDiscount(details.pluginPrice2,details.pluginDiscount)}}</i></div>
        <div v-else class="mdip"> 基础版：￥<i>{{details.pluginPriceStr}}</i>&nbsp;&nbsp;&nbsp;高级版：￥<i>{{details.pluginPriceStr2}}</i></div>
        <div class="explain">
          <p>基础版：一年免费更新升级，仅支持自营项目使用，禁止用于外包，购买奖励{{details.pluginScore1 }}积分。</p>
          <p class="p2">高级版：长期免费更新升级，支持自营项目、外包项目使用，购买奖励{{details.pluginScore2 }}积分。</p>
        </div>
        <div class="explain">
          <span class="discount" v-for="(item,index) in details.confDiscountInfo" :key="'confDis_'+index">会员等级：Lv{{item.level}}以上，优惠：{{item.discount/10}}折</span>
        </div>
        <div class="mdi"><span>版本号：</span>
          <el-select size="mini"
                     v-model="vertionId"
                     @change="vertionChange"
                     placeholder="请选择">
            <el-option v-for="item in details.pluginInfo"
                       :key="item.infoId"
                       :label="item.infoVersion"
                       :value="item.infoId">
            </el-option>
          </el-select>
        </div>
        <div class="mdi"><span>开发商：</span>{{details.memName}}&nbsp;<span>发布日期：</span>{{pluginUpdate | dateFormat}}</div>
        <div class="mdibtn">
          <a href="javascript:;"
             class="buy-btn"
             v-if="isBuy||(details.pluginPrice==0 && details.pluginPrice2==0)"
             @click="downloadPlugin"
          >下载插件</a>
          <a href="javascript:;"
             v-else
             @click="buy"
             class="buy-btn">购买授权</a>
          <a :href="details.demoAddr"
             target="_blank"
             class="preview-btn">查看演示</a>
        </div>
        <div class="agree">
          <p><input type="checkbox"
                   aria-hidden="false"
                   class="el-checkbox__original"
                   value="">&nbsp;&nbsp;同意 <a href="/agreement.html" target="_blank">《Gfast授权协议》</a></p>
        </div>
      </div>

    </div>
    <div class="module-detail">
      <div class="tab-intro">
        <div class="tab-menu">
          <span @click.prevent="active='1'"
                :class="{'active': active == '1'}"><a href="#">插件介绍</a></span>
          <span @click.prevent="active='2'"
                :class="{'active': active == '2'}"><a href="#">版本更新</a></span>
        </div>
        <div v-if="active=='1'"
             class="tab-body markdown-body">
          <vue-markdown ref="content" :source="pluginContent"></vue-markdown>
        </div>
        <div v-if="active=='2'"
             class="tab-body markdown-body">
          <vue-markdown ref="updateLog" :source="pluginUpdateLog"></vue-markdown>
        </div>
      </div>
    </div>
    <el-image-viewer
      v-if="openViewer"
      :initial-index="clickIndex"
      :url-list="contentImgs"
      :on-close="onCloseViewer"
    />
  </div>
</template>

<script>

import mixinImgUrl from '@/utils/mixin/imgUrl.js'
import { one as oneReq, getPluginVertionInfo, getDownLoadInfo, downloadPlugin } from '@/api/plugin'
import buy from '@/views/components/buy/Index'
import impower from '@/views/components/buy/impower.vue'
import { fenToYuanDiscount } from '@/utils/common'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import VueMarkdown from 'vue-markdown'
import 'github-markdown-css'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'ShowModule',
  mixins: [mixinImgUrl],
  components: {
    VueMarkdown,
    ElImageViewer
  },
  data () {
    return {
      details: {},
      isBuy: false,
      active: '1',
      pluginId: 0,
      vertionId: 0, // 版本id
      pluginContent: '', // 插件介绍
      pluginUpdateLog: '', // 更新日志
      contentImgs: [], // 内容图片地址
      clickIndex: 0,
      openViewer: false,
      couponOpen: false,
      pluginUpdate: ''
    }
  },
  computed: {
    pluginImgs () {
      if (Object.keys(this.details).length > 0 && this.details.pluginImgs !== '') {
        const result = JSON.parse(this.details.pluginImgs)
        return Array.isArray(result) ? result : []
      }
      return []
    },
    pluginsImgList () {
      if (Object.keys(this.details).length > 0 && this.details.pluginImgs !== '') {
        const result = JSON.parse(this.details.pluginImgs)
        return result.map(item => {
          return item.url
        })
      }
      return []
    }
  },
  created () {
    const { id } = this.$route.query
    this.pluginId = id
    this.getOne(id).then(res => {
      if (Object.keys(res).length > 0 && Array.isArray(res.pluginInfo)) {
        this.vertionId = res.pluginInfo[0] ? res.pluginInfo[0].infoId : 0
        return this.vertionId
      }
      return 0
    }).then(vertionId => {
      if (vertionId === 0) {
        return
      }
      this.getPluginVertionInfo(vertionId).then(data => {
        if (Object.keys(data).length > 0) {
          this.pluginContent = data.infoContent
          this.pluginUpdateLog = data.infoUpdate
          this.pluginUpdate = data.updatedAt
        }
      })
    })
  },
  watch: {
    pluginContent () {
      this.$nextTick(() => {
        const cImg = []
        const imgs = Array.from(this.$refs.content.$el.getElementsByTagName('img'))
        imgs.map(item => {
          cImg.push(item.getAttribute('src'))
          item.addEventListener('click', (e) => {
            this.openViewer = true
            this.contentImgs.some((v, k) => {
              if (v === e.target.src) {
                this.clickIndex = k
                return true
              }
              return false
            })
          })
        })
        this.contentImgs = cImg
      })
    }
  },
  methods: {
    fenToYuanDiscount,
    // 搜索
    searchPlugin () {
      const searchKey = this.$refs.searchKey.value
      if (searchKey === '') {
        return this.$message.error('搜索关键字必须')
      }
      this.$router.push({ path: '/', query: { searchKey } })
    },
    // 获取插件版本详情
    getPluginVertionInfo (id) {
      return getPluginVertionInfo(id).then(res => {
        const { code, data, msg } = res
        if (code !== 0) {
          this.$message.error(msg)
          return {}
        }
        return data
      })
    },
    vertionChange (value) {
      this.getPluginVertionInfo(value).then(data => {
        if (Object.keys(data).length > 0) {
          this.pluginContent = data.infoContent
          this.pluginUpdateLog = data.infoUpdate
          this.pluginUpdate = data.updatedAt
        }
      })
    },
    getOne (id) {
      return oneReq(id).then(res => {
        const { code, data, msg } = res
        if (code !== 0) {
          this.$message.error(msg)
          return {}
        }
        this.isBuy = data.isBuy || false
        data.info.demoAddr = data.info.demoAddr ? data.info.demoAddr : 'http://v3.g-fast.cn/sys'
        const dataInfo = data.info || {}
        if (!dataInfo.priceDesc) {
          dataInfo.priceDesc = '一年免费更新升级，仅支持自营项目使用，禁止用于外包'
        }
        if (!dataInfo.price2Desc) {
          dataInfo.price2Desc = '长期免费更新升级，支持自营项目、外包项目使用'
        }
        this.details = dataInfo
        this.couponOpen = data.couponOpen
        return data.info || {}
      })
    },

    // 购买授权
    buy () {
      if (this.details.isImpower === 1) {
        const dialogWidth = window.innerWidth > 800 ? '650px' : '95%'
        const impowerDialog = this.$dialog({ title: '填写授权信息', width: dialogWidth, 'close-on-click-modal': false, 'show-close': false }, false).content(h =>
          h(impower, {
            props: { pluginId: _.toNumber(this.pluginId) },
            on: {
              exit: () => {
                impowerDialog.close()
              },
              success: () => {
                impowerDialog.close()
                this.showPayDialog()
              }
            }
          })
        )
      } else {
        this.showPayDialog()
      }
    },
    showPayDialog () {
      const props = {
        standardTitle: this.details.pluginName,
        standardPrice: fenToYuanDiscount(this.details.pluginPrice, this.details.pluginDiscount),
        advancedTitle: this.details.pluginName,
        advancedPrice: fenToYuanDiscount(this.details.pluginPrice2, this.details.pluginDiscount),
        couponOpen: this.couponOpen,
        priceDesc: this.details.priceDesc,
        price2Desc: this.details.price2Desc
      }
      const dialogWidth = window.innerWidth > 800 ? '650px' : '95%'
      const buyDialog = this.$dialog({ title: '立即支付', width: dialogWidth, 'close-on-click-modal': false, 'show-close': false }, false).content(h =>
        h(buy, {
          props: { pluginId: _.toNumber(this.pluginId), ...props },
          on: {
            // 轮询的结果为支付成功
            paySuccess: () => {
              buyDialog.close()
            },
            // 用户自行确认已付款
            confirmPay: ({ type, status }) => {
              // console.log(type, status)
              if (type === 'wechatPay' && status === 'SUCCESS') {
                console.log('使用微信支付成功')
              }
              if (type === 'aliPay' && status === 'TRADE_SUCCESS') {
                console.log('使用阿里支付成功')
              }
              buyDialog.close()
            },
            exit: () => {
              buyDialog.close()
            }
          }
        })
      )
    },
    downloadPlugin () {
      let version = ''
      this.details.pluginInfo.some(item => {
        if (item.infoId === this.vertionId) {
          version = item.infoVersion
          return true
        }
        return false
      })
      getDownLoadInfo({ pluginId: this.details.pluginId, version: version }).then(res => {
        downloadPlugin(this.BaseUrl + '/' + res.data + '&token=' + encodeURIComponent(this.$store.state.user.token))
      })
    },
    onCloseViewer () {
      this.openViewer = false
    }
  }
}
</script>

<style lang="less" scoped>
.show-module {
  width: 100%;
  overflow: hidden;
}

.img-slid-container,
.img-slid{
  height: 460px;
}

.explain{
  padding: 6px;
  font-size: 12px;
  color: #d94140;
  .p2{
    margin-top: 6px;
  }
  .discount{
    margin: 3px 5px 3px 0;
    display: inline-block;
    width: 180px;
  }
}

.module-head-search {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 60px;
  padding: 30px 0;
  background-color: #3c3f69;
  text-align: center;
  .moduletitle {
    font-size: 36px;
    color: #FFF;
    margin-bottom: 10px;
  }

  .moduledesc {
    font-size: 22px;
    color: #FFF;
    margin-bottom: 20px;
  }

  .modulesearch {
    display: flex;
    justify-content: center;
    padding: 0 15px;

    .search-wrapper {
      position: relative;
      width: 100%;
      max-width: 500px;
      height: 44px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      background: #fff;

      input {
        width: calc(100% - 80px);
        height: 100%;
        padding: 0 20px;
        font-size: 14px;
        border: none;
        color: #333;
        outline: none;

        &::placeholder {
          color: #999;
        }
      }

      .module-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 100%;
        line-height: 44px;
        background: #155bd4;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
        user-select: none;

        &:hover {
          background: #1248a5;
        }

        &:active {
          background: #0e3d8c;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .module-head-search {
    margin-top: 0;
    .moduletitle {
      font-size: 24px;
      margin-bottom: 8px;
    }

    .moduledesc {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .modulesearch {
      .search-wrapper {
        height: 40px;
      }

      .module-btn {
        line-height: 40px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .module-head-search {
    .modulesearch {
      .search-wrapper {
        display: flex;
        flex-direction: column;
        height: auto;
        background: transparent;
        box-shadow: none;

        input {
          width: 100%;
          height: 44px;
          border-radius: 4px;
          margin-bottom: 10px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
          background: #fff;
        }

        .module-btn {
          position: static;
          width: 100%;
          height: 44px;
          line-height: 44px;
          border-radius: 4px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
